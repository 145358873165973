var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{ref:"crud_table",attrs:{"fields":[
          //'id',
          'name',
          'identification',
          _vm.getHelperObject('sector', 'provider_sector_id', 'provider_sectors'),
          _vm.helpers.createdAt
        ],"config":{
          url: 'admin/providers',
          route: '/admin/tools/providers',
          name: 'provider',
          params: { _lists: 'provider_sectors' } //,provider_payment_methods' }
        }},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }