<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //'id',
            'name',
            'identification',
            getHelperObject('sector', 'provider_sector_id', 'provider_sectors'),
            helpers.createdAt
          ]"
          :config="{
            url: 'admin/providers',
            route: '/admin/tools/providers',
            name: 'provider',
            params: { _lists: 'provider_sectors' } //,provider_payment_methods' }
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'Providers',
  mixins: [crudMixin]
}
</script>
